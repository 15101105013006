import '../application/stylesheets/index.js.erb';
import '../application/javascripts/index.js.erb';

$.when($.ready).then(function () {
    
    window.MutiselectOptions =  {
        enableClickableOptGroups: true,
        maxHeight: 350,
        buttonWidth: '100%',
        buttonClass: 'multiselect dropdown-toggle btn btn-primary btn-left',
        selectAllText: 'Select all',
        filterPlaceholder: 'Search',
        nonSelectedText: 'None selected',
        nSelectedText: 'selected',
        allSelectedText: 'All selected',
        enableFiltering: true
    };

    $('#mobile-nav').hcOffcanvasNav({
        maxWidth: false,
        levelTitles: true,
        pushContent: false,
        closeLevels: true,
        position: 'right',
        customToggle: $('.toggle'),
        labelClose: 'Close',
        labelBack: 'Back'
    });
    
});
